/* ==========================================================================
   #CALENDAR
   ========================================================================== */

/**
 *
 * `c-calendar` component isn't asscoited with `fullcalendar` plugin, 
 * `fullcalender` has its own custom styles in `venndor` folder.
 * 
 * `c-calendar` is calendar widget that you will find in `ui-elements.html` 
 *  page.
 */

.c-calendar {
	display: inline-block;
	max-width: $calendar-max-width;
	margin: 0;
	padding: 0;
	border: $calendar-border-width solid $calendar-border-color;
	border-radius: $calendar-border-radius;
	background-color: $calendar-background-color;
}

.c-calendar__header {
	display: inline-block;
	font-size: $calendar-header-font-size;
}

.c-calendar__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $calendar-nav-padding;
	border-bottom: $calendar-border-width solid $calendar-border-color;
	text-align: center;
}

.c-calendar__control {
	color: $calendar-control-color;
}

.c-calendar__picker {
	padding: $calendar-picker-padding;
	text-align: center;
}


/**
 * 1. Remove `abbr` default decaoration
 */

.c-calendar__day {
	color: $calendar-day-color;
	font-size: $calendar-day-font-size;
	font-weight: $font-weight-normal;
	text-transform: uppercase;

	abbr { /* [1] */
		border-bottom: 0;
		text-decoration: none;
	}
}


/**
 * 1. Calendar date background (circualr shape)
 */

.c-calendar__date {
	position: relative;
	padding: $calendar-date-padding;
	color: $calendar-date-color;
	cursor: pointer;
	z-index: $z-index-200;

	&.is-disabled {
		opacity: .5;
	}

	&::before { /* [1] */
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		width: $calendar-date-hover-width;
		height: $calendar-date-hover-height;
		margin: 0 auto;
		padding: 0;
		transform: translateY(-50%);
		border-radius: 100%;
		background-color: $calendar-date-background-color;
		text-align: center;
		content: " ";
		opacity: 0;
		z-index: -$z-index-100;
	}

	&:hover::before {
		opacity: .4;
	}

	&.is-active {
		color: $calendar-date-active-color;

		&::before {
			background-color: $calendar-date-active-background-color;
			opacity: 1;
		}
	}
}
