.c-login__head {
    padding: $spacer 0;
}
.c-login__brand {
    top: -50px;
    width: 200px;
}
.c-login__footer-link:hover {
    color: #f60;
    text-decoration: underline;
}



.c-navbar__brand {
    width: 150px;
    height: auto;
    padding-top: 10px;
}


.c-alert {
    margin-bottom: $spacer;
    border-radius: 0;
}

.c-input {
    border-radius: 0;
}

.c-btn {
    border-radius: 0;
    box-shadow: 2px 3px 5px rgba(0,0,0,.2);
    transition: all .2s ease-in,all .3s ease-out;
}
.c-btn:hover {
    box-shadow: 3px 4px 5px rgba(0,0,0,.2);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.c-btn--info {
    border: 0;
    background-image: none;
    background-color: #0066b3;
}
.c-btn--info:hover {
    color: white;
    background-image: none;
    background-color: #0066b3;
}

.c-btn--warning {
    border: 0;
    background-image: none;
    background-color: #f60;
}
.c-btn--warning:hover {
    color: white;
    background-image: none;
    background-color: #f60;
}

.c-input:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.c-nav__item {
    transition: all .2s ease-in,all .3s ease-out;
}
.c-nav__item:hover,
.c-nav__item.active {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.c-nav__item:hover .c-nav__link,
.c-nav__item.active .c-nav__link {
    color: #0066b3;
}

div.avatar__icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
    font-size: 120%;
}

.c-project-card__head a:hover {
    text-decoration: none;
}
.c-project-card__head a .c-project-card__title {
    color: #0066b3;
}
.c-project-card__head a:hover .c-project-card__title {
    color: #f60;
}
